import { getServiceCode } from '../../utils';
import { AUTHApi } from '../../network';

export const isUserHaveServiceActive = async () => {
  const serviceCode = getServiceCode();
  if (!serviceCode) {
    return true;
  }

  const response = await AUTHApi.security.me.services.getOne(serviceCode);

  return response.service.isActive;
};
