import { useCallback, useEffect, useState } from 'react';
import { APIDataCollection, AppConfig, Metadata, Product } from '../../types';
import { QGApi } from '../../network';

export type ProductsConfig = {
  page?: number;
  limit?: number;
  sort?: Array<string> | null;
  filters?: URLSearchParams | null;
  searchQuery?: string | null;
  productType?: 'plv' | 'frame';
  fields?: Array<keyof Product>;
  config?: AppConfig;
  clientIdForOffer?: string;
};

export const useProducts = (productConfig: ProductsConfig) => {
  const { page, limit, sort, filters, searchQuery, productType, fields, config, clientIdForOffer } = productConfig;

  const [products, setProducts] = useState<Array<Product>>([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [productsMetadata, setProductsMetadata] = useState<Metadata | undefined>();
  const [errorProducts, setErrorProducts] = useState(null);
  const [currentPage, setCurrentPage] = useState(page || 1);

  useEffect(() => {
    if (page) {
      setCurrentPage(page);
    }
  }, [page]);

  const findProduct = useCallback(() => {
    const params = new URLSearchParams();
    const defaultFields = ['id', 'model', 'properties', 'offers', 'brand', 'category', 'images', 'name'];
    (fields || defaultFields).forEach(value => {
      params.append('fields[]', value);
    });

    if (clientIdForOffer) {
      params.append('offers[]', clientIdForOffer);
    }

    if (sort) {
      sort.forEach(value => {
        params.append('sorts[]', value);
      });
    }

    if (config) {
      (config.productScope || []).forEach(value => {
        params.append(value[0], value[1]);
      });

      if (productType === 'plv') {
        (config.plvScope || []).forEach(value => {
          params.append(value[0], value[1]);
        });
      }
      if (productType === 'frame') {
        (config.frameScope || []).forEach(value => {
          params.append(value[0], value[1]);
        });
      }
    }

    params.append('page', currentPage.toString());
    if (limit) {
      params.append('nbresults', limit.toString());
    }

    if (searchQuery) {
      params.append('search', searchQuery);
    }

    if (filters) {
      filters.forEach((value, key) => {
        params.append(key, value);
      });
    }

    QGApi.catalog.products
      .get(params.toString(), { accept: 'application/ld+json' })
      .then((response: APIDataCollection<Product>) => {
        const result = Object.values(response['hydra:member']);
        setProducts(result);
        setProductsMetadata({
          count: response['hydra:totalItems'],
          first: response['hydra:view']['hydra:first'],
          last: response['hydra:view']['hydra:first'],
          previous: response['hydra:view']['hydra:previous'],
          next: response['hydra:view']['hydra:next'],
        });
      })
      .catch(error => setErrorProducts(error))
      .finally(() => setLoadingProducts(false));
  }, [clientIdForOffer, config, currentPage, fields, filters, limit, productType, searchQuery, sort]);

  useEffect(() => {
    let timeout: any;
    let cancel = false;
    if (!cancel) {
      setLoadingProducts(true);
      findProduct();
    }

    return () => {
      clearTimeout(timeout);
      cancel = true;
      setLoadingProducts(false);
    };
  }, [findProduct]);

  const loadMoreProducts = () => {
    setCurrentPage(currentPage + 1);
  };

  return {
    products,
    loadingProducts,
    errorProducts,
    productsMetadata,
    loadMoreProducts,
  };
};
