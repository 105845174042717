import { startsWith } from 'lodash';

export const ping = async (host: string, port?: number): Promise<number> => {
  return new Promise((resolve, reject) => {
    const started = new Date().getTime();
    const http = new XMLHttpRequest();

    let url = host;
    if (!startsWith(url, 'http')) {
      if (port === 443) {
        url = 'https://';
      } else {
        url = 'http://' + url;
      }
    }
    if (port && port !== 443) {
      url = url.replace(/\/$/, '') + ':' + port;
    }
    http.open('GET', url, /* async */ true);
    http.timeout = 5000;
    http.ontimeout = () => {
      resolve(999999);
    };
    http.onreadystatechange = function () {
      if (http.readyState === 4) {
        const ended = new Date().getTime();

        const milliseconds = ended - started;

        return resolve(milliseconds);
      }
    };
    try {
      http.send(null);
    } catch (exception) {
      // this is expected
      reject(exception);
    }
  });
};
