import { createJob, getJob, getJobs } from './job';

export * from './job';

export const SCHEDULERApi = {
  jobs: {
    get: getJobs,
    getOne: getJob,
    create: createJob,
  },
};
