import { callSchedulerAPI, DEFAULT_QUERY_OPTIONS } from '../../../callApi';
import { QueryOptions } from '../../../types';

export const getJob = (jobId: string, params: string, queryOptions: QueryOptions = DEFAULT_QUERY_OPTIONS) => {
  return callSchedulerAPI(
    `/job/jobs/${jobId}`,
    params,
    {
      method: 'GET',
      headers: {
        Accept: queryOptions.accept,
      },
    },
    queryOptions.toJson,
  );
};
