import { getAuthApiURL, getToken } from '../../../utils';

export const loginCheck = async (username: string, password: string) => {
  return fetch(getAuthApiURL() + '/login_check', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + (await getToken()),
    },
    body: JSON.stringify({
      _username: username,
      _password: password,
    }),
  });
};
