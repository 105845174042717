import { getAuthApiURL, getToken } from '../../../utils';

export const logUserLoginCheck = async (username: string, password: string, token?: string) => {
  return fetch(getAuthApiURL() + '/loguser/login_check', {
    method: 'POST',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token || (await getToken()),
    },
    body: JSON.stringify({
      _username: username,
      _password: password,
    }),
  });
};
