import { useCallback, useEffect, useState } from 'react';
import { APIDataCollection, Metadata } from '../../types';
import { getJobs, SCHEDULERApi } from '../../network';
import { Job } from '../../types/scheduler/Job';

export type ProductsConfig = {
  page?: number;
  limit?: number;
  filters?: URLSearchParams | null;
  searchQuery?: string | null;
  fields?: Array<keyof Job>;
};

export const useJobs = (productConfig: ProductsConfig) => {
  const { page, limit, filters, searchQuery, fields } = productConfig;

  const [jobs, setJobs] = useState<Array<Job>>([]);
  const [loadingJobs, setLoadingJobs] = useState(true);
  const [jobsMetadata, setJobsMetadata] = useState<Metadata | undefined>();
  const [errorJobs, setErrorJobs] = useState(null);
  const [currentPage, setCurrentPage] = useState(page || 1);

  useEffect(() => {
    if (page) {
      setCurrentPage(page);
    }
  }, [page]);

  const findProduct = useCallback(() => {
    const params = new URLSearchParams();
    (fields || []).forEach(value => {
      params.append('fields[]', value);
    });

    params.append('page', currentPage.toString());
    if (limit) {
      params.append('nbresults', limit.toString());
    }

    if (searchQuery) {
      params.append('search', searchQuery);
    }

    if (filters) {
      filters.forEach((value, key) => {
        params.append(key, value);
      });
    }

    SCHEDULERApi.jobs
      .get(params.toString(), { accept: 'application/ld+json' })
      .then((response: APIDataCollection<Job>) => {
        const result = Object.values(response['hydra:member']);
        setJobs(result);
        setJobsMetadata({
          count: response['hydra:totalItems'],
          first: response['hydra:view']['hydra:first'],
          last: response['hydra:view']['hydra:first'],
          previous: response['hydra:view']['hydra:previous'],
          next: response['hydra:view']['hydra:next'],
        });
      })
      .catch(error => setErrorJobs(error))
      .finally(() => setLoadingJobs(false));
  }, [currentPage, fields, filters, limit, searchQuery]);

  useEffect(() => {
    let timeout: any;
    let cancel = false;
    if (!cancel) {
      setLoadingJobs(true);
      findProduct();
    }

    return () => {
      clearTimeout(timeout);
      cancel = true;
      setLoadingJobs(false);
    };
  }, [findProduct]);

  const loadMoreJobs = () => {
    setCurrentPage(currentPage + 1);
  };

  return {
    jobs,
    loadingJobs,
    jobsMetadata,
    errorJobs,
    loadMoreJobs,
  };
};
