import {
  deletePartner,
  getPartner,
  getPartners,
  putPartner,
  getPartnerServices,
  getPartnerService,
  putPartnerService,
  postPartnerService,
  deletePartnerService,
  postPartnerUser,
  getPartnerUsers,
  getPartnerUser,
  putPartnerUser,
  deletePartnerUser,
} from './Partner';
import {
  deleteApp,
  deleteAppUser,
  deleteHost,
  deleteUserService,
  getApp,
  getApplicationTypes,
  getApps,
  getAppUser,
  getAppUsers,
  getHost,
  getHosts,
  getService,
  getServices,
  getUserServices,
  postApp,
  postAppUser,
  postHost,
  postUserService,
  putApp,
  putAppUser,
  putHost,
  putUserService,
} from './System';
import { loginCheck, logUserLoginCheck, logUserLoginImpersonate } from './Security';
import { me } from './Security/me';
import { meService } from './Security/meService';

export * from './Partner';
export * from './System';

export const AUTHApi = {
  security: {
    me: { getOne: me, services: { getOne: meService } },
    loginCheck: { create: loginCheck },
    logUser: {
      loginCheck: { create: logUserLoginCheck },
      loginImpersonate: { create: logUserLoginImpersonate },
    },
  },
  partner: {
    partners: {
      get: getPartners,
      getOne: getPartner,
      create: null,
      update: putPartner,
      delete: deletePartner,
    },
    services: {
      get: getPartnerServices,
      getOne: getPartnerService,
      update: putPartnerService,
      create: postPartnerService,
      delete: deletePartnerService,
    },
    users: {
      create: postPartnerUser,
      get: getPartnerUsers,
      getOne: getPartnerUser,
      update: putPartnerUser,
      delete: deletePartnerUser,
    },
  },
  system: {
    applications: {
      create: postApp,
      get: getApps,
      getOne: getApp,
      update: putApp,
      delete: deleteApp,
    },
    applicationsTypes: { getOne: null, get: getApplicationTypes },
    hosts: {
      create: postHost,
      get: getHosts,
      getOne: getHost,
      update: putHost,
      delete: deleteHost,
    },
    services: {
      get: getServices,
      getOne: getService,
    },
    user: {
      create: postAppUser,
      get: getAppUsers,
      getOne: getAppUser,
      update: putAppUser,
      delete: deleteAppUser,
      services: {
        create: postUserService,
        get: getUserServices,
        getOne: getUserServices,
        update: putUserService,
        delete: deleteUserService,
      },
    },
  },
};
