import { callQGAPI, DEFAULT_QUERY_OPTIONS } from '../../../callApi';
import { QueryOptions } from '../../../types';

export const getCommercialProducts = (
  repRessource: string,
  params: string,
  queryOptions: QueryOptions = DEFAULT_QUERY_OPTIONS,
) => {
  return callQGAPI(
    `${repRessource}/products`,
    params,
    {
      method: 'GET',
      headers: {
        Accept: queryOptions.accept,
      },
    },
    queryOptions.toJson,
  );
};
