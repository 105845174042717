import { callQGAPI, DEFAULT_QUERY_OPTIONS } from '../../../callApi';
import { QueryOptions } from '../../../types';

export const getPossibleValueForProperty = (
  property: string,
  params: string,
  queryOptions: QueryOptions = DEFAULT_QUERY_OPTIONS,
) => {
  return callQGAPI(
    '/catalog/product_properties/' + property + '/values',
    params,
    {
      method: 'GET',
      headers: {
        Accept: queryOptions.accept,
      },
    },
    queryOptions.toJson,
  );
};
