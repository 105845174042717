import { useEffect, useState } from 'react';
import { initMorelAuth } from '../utils';
import { InitMorelAuthParams } from '../utils/global';

export const useInitMorelAuth = (params: InitMorelAuthParams) => {
  const [loadingInitMorelAuth, setLoadingInitMorelAuth] = useState(true);
  const [errorInitMorelAuth, setErrorInitMorelAuth] = useState<null | string>(null);

  useEffect(() => {
    setLoadingInitMorelAuth(true);
    initMorelAuth(params)
      .catch(e => setErrorInitMorelAuth(e.message))
      .finally(() => setLoadingInitMorelAuth(false));
  }, [params]);

  return { loadingInitMorelAuth, errorInitMorelAuth };
};
