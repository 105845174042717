/**
 * Retourne l'URL complete à l'API à partir de l'entrée passée
 */

import { getAuthApiURL, removeRefreshToken, removeToken, setRefreshToken, setToken } from '../utils';
import { getM3ApiURL, getQgApiURL, getSchedulerApiUrl, getToolsApiURL } from '../utils/global';
import { QueryOptions } from '../types';
import { getRefreshToken, getToken } from '../utils/tokens';

export const refreshAuthData = async (oldToken: string, refreshToken: string) => {
  // On se connecte à l'API avec les infos de l'app
  // const refreshToken = getUserRefreshToken();
  const response = await fetch(getAuthApiURL() + '/token/refresh', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + oldToken,
    },
    body: JSON.stringify({ refresh_token: refreshToken }),
  });

  if (response.status === 401) {
    removeToken();
    removeRefreshToken();
  } else {
    const responseJson = await response.json();
    if (responseJson.token) {
      setToken(responseJson.token);
      setRefreshToken(responseJson.refresh_token);

      return;
    }
  }

  throw new Error('Failed to refresh tokens');
};
/**
 * Fonction d'appel à l'API
 * @private
 */
const callApi = async (apiUrl: string, entry: string, params: string, options: any, asJsonResponse: boolean = true) => {
  if (!apiUrl) {
    throw new Error(`apiUrl is required, ${apiUrl} given`);
  }

  if (typeof options.headers === 'undefined') {
    options.headers = {};
  }
  if (!options.headers.Authorization) {
    const token = await getToken();
    options.headers.Authorization = 'Bearer ' + token;
  }
  let url = apiUrl + entry;
  if (params !== null) {
    url = `${url}?${params}`;
  }

  try {
    const response = await fetch(url, options);
    if (response) {
      if (response.status === 401) {
        const jsonResponse = await response.json();
        if (
          jsonResponse.description === 'Expired JWT Token' ||
          jsonResponse.description === 'Token is expired' ||
          jsonResponse.description === 'Unauthorized'
        ) {
          try {
            await refreshAuthData(await getToken(), await getRefreshToken());
          } catch (e) {
            throw new Error(e);
          }
          // On relance l'appel, avec le nouveau token
          const token = await getToken();
          options.headers.Authorization = 'Bearer ' + token;
          const retryResponse = await fetch(url.toString(), options);
          return retryResponse.json();
        }
      } else {
        if (response.status >= 200 && response.status < 300) {
          if (asJsonResponse) {
            const jsonResponse = await response.json();
            return await jsonResponse;
          }
          return response;
        }

        throw new Error((await response.json()).description);
      }
    }
  } catch (e) {
    throw new Error(e);
  }
};

export const DEFAULT_QUERY_OPTIONS: QueryOptions = { accept: 'application/json', toJson: true };

export const callAuthAPI = (entry: string, params: string, options: any, jsonResponse: boolean = true) => {
  return callApi(getAuthApiURL(), entry, params, options, jsonResponse);
};

export const callQGAPI = (entry: string, params: string, options: any, jsonResponse: boolean = true) => {
  return callApi(getQgApiURL(), entry, params, options, jsonResponse);
};

export const callM3API = (entry: string, params: string, options: any, jsonResponse: boolean = true) => {
  return callApi(getM3ApiURL(), entry, params, options, jsonResponse);
};

export const callToolsAPI = (entry: string, params: string, options: any, jsonResponse: boolean = true) => {
  return callApi(getToolsApiURL(), entry, params, options, jsonResponse);
};

export const callSchedulerAPI = (entry: string, params: string, options: any, jsonResponse: boolean = true) => {
  return callApi(getSchedulerApiUrl(), entry, params, options, jsonResponse);
};
