import { selectNearestApiHost } from './selectNearestApiHost';

export type Storage = {
  setItem: ((key: string, value: string) => void) | null;
  getItem: ((key: string) => Promise<string | null>) | null;
  removeItem: ((key: string) => void) | null;
};

const checkNull = <T>(fct: T, error: string) => {
  if (fct === null) {
    throw new Error(error);
  }

  return fct!;
};

let STORAGE: Storage = {
  setItem: null,
  getItem: null,
  removeItem: null,
};

export const getLocalStorage = () => {
  return {
    setItem: checkNull<Storage['setItem']>(
      STORAGE.setItem,
      'You need to provide a set storage function in initGlobalAuth()',
    ),
    getItem: checkNull(STORAGE.getItem, 'You need to provide a get storage function in initGlobalAuth()'),
    removeItem: checkNull(STORAGE.removeItem, 'You need to provide a remove storage function in initGlobalAuth()'),
  };
};

/** ************************************************ **/

export type ApiList = 'AUTH' | 'QG' | 'M3' | 'TOOLS' | 'SCHEDULER';

let AUTH_API_URL: string | null = null;
let QG_API_URL: string | null | undefined = null;
let M3_API_URL: string | null | undefined = null;
let TOOLS_API_URL: string | null | undefined = null;
let SCHEDULER_API_URL: string | null | undefined = null;

export const getAuthApiURL = () => {
  if (!AUTH_API_URL) {
    throw new Error('You need to provide an auth api url in initGlobalAuth function');
  }

  return AUTH_API_URL;
};

export const getQgApiURL = () => {
  if (!QG_API_URL) {
    throw new Error('You need to provide a qg api url in initGlobalAuth function');
  }

  return QG_API_URL;
};

export const setQgApiUrl = (url: string) => {
  QG_API_URL = url;
};
export const getM3ApiURL = () => {
  if (!M3_API_URL) {
    throw new Error('You need to provide a M3 api url in initGlobalAuth function');
  }

  return M3_API_URL;
};

export const getToolsApiURL = () => {
  if (!TOOLS_API_URL) {
    throw new Error('You need to provide a tools api url in initGlobalAuth function');
  }

  return TOOLS_API_URL;
};

export const getSchedulerApiUrl = () => {
  if (!SCHEDULER_API_URL) {
    throw new Error('You need to provide a scheduler api url in initGlobalAuth function');
  }

  return SCHEDULER_API_URL;
};

export const setApiUrl = (api: ApiList, url: string) => {
  let previousUrl = '';
  switch (api) {
    case 'AUTH':
      previousUrl = getAuthApiURL();
      AUTH_API_URL = url;
      break;
    case 'QG':
      previousUrl = getQgApiURL();
      QG_API_URL = url;
      break;
    case 'M3':
      previousUrl = getM3ApiURL();
      M3_API_URL = url;
      break;
    case 'TOOLS':
      previousUrl = getToolsApiURL();
      TOOLS_API_URL = url;
      break;
    case 'SCHEDULER':
      previousUrl = getSchedulerApiUrl();
      SCHEDULER_API_URL = url;
      break;
    default:
      throw new Error('Unknown api');
  }

  return previousUrl;
};

export const resetApiUrl = () => {
  AUTH_API_URL = null;
  QG_API_URL = null;
  M3_API_URL = null;
  TOOLS_API_URL = null;
  SCHEDULER_API_URL = null;
};

/** ************************************************ **/

let SERVICE_CODE: string | null = null;

export const getServiceCode = () => {
  // if (!SERVICE_CODE && SERVICE_CODE !== '') {
  //   throw new Error('You need to provide an service code in initGlobalAuth function');
  // }

  return SERVICE_CODE;
};

/** ************************************************ **/
export type InitMorelAuthParams = {
  storage: Storage;
  apiUrls: {
    authApiUrl: Array<string> | string;
    qgApiUrl?: string;
    m3ApiUrl?: string;
    toolsApiUrl?: string;
    schedulerApiUrl?: string;
  };
  serviceCode: string;
};

export const initMorelAuth = async (params: InitMorelAuthParams) => {
  const { storage, serviceCode, apiUrls } = params;
  STORAGE = storage;
  // on récupère l'api d'authentification la plus proche
  if (Array.isArray(apiUrls.authApiUrl)) {
    AUTH_API_URL = await selectNearestApiHost(apiUrls.authApiUrl);
  } else {
    AUTH_API_URL = apiUrls.authApiUrl;
  }
  QG_API_URL = apiUrls?.qgApiUrl;
  M3_API_URL = apiUrls?.m3ApiUrl;
  TOOLS_API_URL = apiUrls?.toolsApiUrl;
  SCHEDULER_API_URL = apiUrls?.schedulerApiUrl;
  SERVICE_CODE = serviceCode;
};
