import { AppUser, PartnerUser } from '../../types';
import { AUTHApi } from '../../network';
import { getLoggedUserService } from './getLoggedUserService';

export const getUser = async (fields?: Array<keyof PartnerUser | keyof AppUser>) => {
  const params = new URLSearchParams();
  if (fields) {
    fields.forEach(field => params.append('fields[]', field));
  }

  try {
    const response = await AUTHApi.security.me.getOne(params.toString());

    const userService = await getLoggedUserService();

    if (userService) {
      return { user: response, service: userService };
    }

    return { user: response };
  } catch (e) {
    throw new Error(e);
  }
};
