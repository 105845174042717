import { useCallback, useEffect, useState } from 'react';
import { APIDataCollection, Metadata, Product } from '../../types';
import { QGApi } from '../../network';

export type CommercialProductsConfig = {
  page?: number;
  limit?: number;
  filters?: URLSearchParams | null;
  searchQuery?: string | null;
  fields?: Array<keyof Product>;
};

export const useCommercialProducts = (
  commercialRessource: string | null,
  commercialProductsConfig: CommercialProductsConfig,
) => {
  const { page, limit, filters, searchQuery, fields } = commercialProductsConfig;

  const [commercialProducts, setCommercialProducts] = useState<Array<Product>>([]);
  const [loadingCommercialProducts, setLoadingCommercialProducts] = useState(true);
  const [commercialProductsMetadata, setCommercialProductsMetadata] = useState<Metadata | undefined>();
  const [errorCommercialProducts, setErrorCommercialProducts] = useState(null);
  const [currentPage, setCurrentPage] = useState(page || 1);

  useEffect(() => {
    if (page) {
      setCurrentPage(page);
    }
  }, [page]);

  const findCommercialProducts = useCallback(
    repRessource => {
      const params = new URLSearchParams();
      (fields || []).forEach(value => {
        params.append('fields[]', value);
      });

      params.append('pagination', 'true');

      params.append('page', currentPage.toString());
      if (limit) {
        params.append('nbresults', limit.toString());
      }

      if (searchQuery) {
        params.append('search', searchQuery);
      }

      if (filters) {
        filters.forEach((value, key) => {
          params.append(key, value);
        });
      }

      QGApi.commercial.representants.products
        .get(repRessource, params.toString(), { accept: 'application/ld+json' })
        .then((response: APIDataCollection<Product>) => {
          const result = Object.values(response['hydra:member']);
          setCommercialProducts(result);
          setCommercialProductsMetadata({
            count: response['hydra:totalItems'],
            first: response['hydra:view']['hydra:first'],
            last: response['hydra:view']['hydra:first'],
            previous: response['hydra:view']['hydra:previous'],
            next: response['hydra:view']['hydra:next'],
          });
        })
        .catch(error => {
          setErrorCommercialProducts(error);
        })
        .finally(() => setLoadingCommercialProducts(false));
    },
    [currentPage, fields, filters, limit, searchQuery],
  );

  useEffect(() => {
    let timeout: any;
    let cancel = false;
    if (!cancel && commercialRessource) {
      setLoadingCommercialProducts(true);
      findCommercialProducts(commercialRessource);
    }

    return () => {
      clearTimeout(timeout);
      cancel = true;
      setLoadingCommercialProducts(false);
    };
  }, [commercialRessource, findCommercialProducts]);

  const loadMoreCommercialProducts = () => {
    setCurrentPage(currentPage + 1);
  };

  return {
    commercialProducts,
    loadingCommercialProducts,
    errorCommercialProducts,
    commercialProductsMetadata,
    loadMoreCommercialProducts,
  };
};
