import { ApiList, setApiUrl } from '../utils/global';
import { useEffect } from 'react';

export const useRunInContext = <F extends (...params: any[]) => any>(
  api: ApiList,
  url: string,
  hook: F,
  params: Parameters<F>,
): ReturnType<F> => {
  useEffect(() => {
    let previousUrl: null | string = null;
    if (url) {
      previousUrl = setApiUrl(api, url);
    }
    return () => {
      if (url && previousUrl) {
        setApiUrl(api, previousUrl);
      }
    };
  }, [api, hook, params, url]);

  return hook(...params);
};

export const runInContext = async <F extends (...params: any[]) => any>(
  api: ApiList,
  url: string,
  networkFunction: F,
  params: Parameters<F>,
): Promise<ReturnType<F>> => {
  return new Promise((resolve, reject) => {
    let previousUrl: null | string = null;
    if (url) {
      previousUrl = setApiUrl(api, url);
      const result = networkFunction(...params)
        .then(() => {
          if (previousUrl) {
            setApiUrl(api, previousUrl);
          }
          resolve(result);
        })
        .catch(reject);
    }
  });
};
