import { getPossibleValueForProperty, getProduct, getProducts } from './catalog';
import { getCommercial, getCommercialProducts } from './commercial';
import {
  createCartInGroup,
  createLineInCart,
  deleteAllLinesFromCart,
  deleteCart,
  deleteCartGroup,
  deleteCartLine,
  getCartGroups,
  getClientOrders,
  getClientShippingAddresses,
  getCustomerClient,
  getCustomerClients,
  getOrder,
  getQGOrders,
  putCart,
  putCartLine,
  sendCartGroupOrders,
} from './customer';
import { getImageForProduct } from './catalog/getImageForProduct';

export * from './catalog';
export * from './customer';
export * from './commercial';

export const QGApi = {
  catalog: {
    productProperties: { values: { get: getPossibleValueForProperty } },
    products: {
      get: getProducts,
      getOne: getProduct,
      discounts: { get: null },
      marketings: { get: null, create: null, update: null, delete: null },
      images: { get: getImageForProduct },
    },
    productMorelMap: { get: null, getOne: null },
  },
  commercial: {
    representants: {
      get: getCommercial,
      client: { get: null },
      products: { get: getCommercialProducts },
    },
  },
  customer: {
    cartsGroups: {
      get: getCartGroups,
      delete: deleteCartGroup,
      cart: { create: createCartInGroup },
      convert_to_orders: { update: sendCartGroupOrders },
    },
    cartLines: {
      update: putCartLine,
      delete: deleteCartLine,
    },
    carts: {
      update: putCart,
      delete: deleteCart,
      lines: { create: createLineInCart, deleteAll: deleteAllLinesFromCart },
    },
    clients: {
      get: getCustomerClients,
      getOne: getCustomerClient,
      create: null,
      update: null,
      comment: { get: null },
      events: { get: null },
      frameBoard: { get: null },
      offers: { get: null },
      orders: { get: getClientOrders },
      qualifications: { get: null },
      shippingAddresses: { get: getClientShippingAddresses },
      stats: { get: null },
      tasks: { get: null },
    },
    comments: {
      create: null,
      update: null,
    },
    events: {
      create: null,
      update: null,
    },
    frameBoards: {
      get: null,
      create: null,
      count: { in: null },
    },
    orders: {
      get: getQGOrders,
      getOne: getOrder,
      create: null,
      update: null,
    },
    qualifications: {
      create: null,
      update: null,
    },
    tasks: {
      create: null,
      update: null,
    },
  },
  dictionary: {
    getOne: null,
  },
  erp: {
    lastSynchro: { getOne: null },
  },
};
