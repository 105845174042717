import { callAuthAPI, DEFAULT_QUERY_OPTIONS } from '../../../callApi';
import { LoginResponse, QueryOptions } from '../../../types';

export const me = (params: string) => {
  return callAuthAPI('/me', params, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });
};
