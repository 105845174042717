import { callAuthAPI } from '../../../callApi';
import md5 from 'md5';
import { LoginResponse } from '../../../types';

export const logUserLoginImpersonate = (
  thieveUsername: string,
  impersonatedUsername: string,
): Promise<LoginResponse> => {
  return callAuthAPI('/loguser/login_impersonate', '', {
    method: 'POST',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      _username: impersonatedUsername,
      _password: md5(`${impersonatedUsername}#${thieveUsername}`),
    }),
  });
};
