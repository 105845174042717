import { useEffect, useState } from 'react';
import { QGApi } from '../../network';
import { Product } from '../../types';

export const useProduct = (productId: string | undefined) => {
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [product, setProduct] = useState<Product | null>(null);
  const [errorProduct, setErrorProduct] = useState<string | null>(null);

  useEffect(() => {
    if (productId) {
      setLoadingProduct(true);

      const params = new URLSearchParams();
      const fields: Array<keyof Product> = [
        'id',
        'sku',
        'ean',
        'name',
        'model',
        'properties',
        'offers',
        'brand',
        'category',
        'images',
        'name',
        'marketing',
      ];

      fields.forEach(value => {
        params.append('fields[]', value);
      });

      QGApi.catalog.products
        .getOne(productId, params.toString())
        .then(response => {
          setProduct(response);
        })
        .catch(error => {
          setErrorProduct(error.message);
        })
        .finally(() => {
          setLoadingProduct(false);
        });
    }
  }, [productId]);

  return { product, loadingProduct, errorProduct };
};
