import { getLocalStorage } from './global';

export const getImpersonatedToken = async () => {
  const token = await getLocalStorage().getItem(`impersonated_user_token`);
  if (!token) {
    return null;
  }
  return JSON.parse(token);
};

export const setImpersonatedToken = (token: string) => {
  getLocalStorage().setItem(`impersonated_user_token`, JSON.stringify(token));
};

export const removeToken = () => {
  getLocalStorage().removeItem(`token`);
};

export const removeRefreshToken = () => {
  getLocalStorage().removeItem(`refresh_token`);
};

export const getToken = async () => {
  const token = await getLocalStorage().getItem(`token`);
  if (!token) {
    return null;
  }
  return JSON.parse(token);
};

export const getRefreshToken = async () => {
  const refreshToken = await getLocalStorage().getItem(`refresh_token`);
  if (!refreshToken) {
    return null;
  }
  return JSON.parse(refreshToken);
};

export const setToken = (token: string) => {
  getLocalStorage().setItem(`token`, JSON.stringify(token));
};

export const setRefreshToken = (token: string) => {
  getLocalStorage().setItem(`refresh_token`, JSON.stringify(token));
};
