import { callSchedulerAPI, DEFAULT_QUERY_OPTIONS } from '../../../callApi';
import { Job, QueryOptions } from '../../../types';

export const createJob = (job: Partial<Job>, queryOptions: QueryOptions = DEFAULT_QUERY_OPTIONS) => {
  return callSchedulerAPI(
    '/job/jobs',
    '',
    {
      method: 'POST',
      headers: {
        Accept: queryOptions.accept,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(job),
    },
    queryOptions.toJson,
  );
};
