import { getRefreshToken, getToken, setImpersonatedToken, setRefreshToken, setToken } from '../utils';
import { AUTHApi } from '../network';
import { refreshAuthData } from '../callApi/callApi';
import { AppUser, PartnerUser } from '../types';
import { getLoggedUserService, isUserHaveServiceActive } from './user';
import { isServiceActive } from './isServiceActive';

export const isUsingApp = (obj: any): obj is PartnerUser | AppUser => {
  return (obj as PartnerUser | AppUser).usingApp !== undefined;
};

/**
 * permet de s'assurer que l'entité associé au token actif peux se connecter
 */
export const checkAuthenticate = async (checkService?: boolean) => {
  const token = await getToken();

  if (!token) {
    return false;
  }

  try {
    const params = new URLSearchParams();
    const fields = ['usingApplication', 'isActive'];
    fields.forEach(field => {
      params.append('fields[]', field);
    });

    const response = await AUTHApi.security.me.getOne(params.toString());

    let result = response.isActive;
    if (isUsingApp(response)) {
      if (response.usingApp) {
        result = response.isActive && response.usingApp.isActive;
      }
    }

    if (checkService) {
      // on check si l'entité à un service et s'il est actif
      const serviceActive = isUserHaveServiceActive();
      if (!serviceActive) {
        return false;
      }
    }

    return result;
  } catch (e) {
    return false;
  }
};

export const authenticate = async (username: string, password: string, checkService?: boolean) => {
  const response = await AUTHApi.security.loginCheck.create(username, password);
  const jsonResponse = await response.json();
  if (response.status === 200) {
    if (jsonResponse?.token) {
      setToken(jsonResponse.token);
    }
    if (jsonResponse?.refresh_token) {
      setRefreshToken(jsonResponse.refresh_token);
    }
    if (checkService) {
      const serviceActive = isUserHaveServiceActive();
      if (!serviceActive) {
        return false;
      }
    }
    return response;
  }
  throw new Error(jsonResponse.description);
};

export const authenticateFromApp = async (
  appUsername: string,
  appPassword: string,
  username: string,
  password: string,
) => {
  // si le token est invalide
  const response = await AUTHApi.security.loginCheck.create(appUsername, appPassword);
  const jsonResponse = await response.json();
  if (response.status === 200) {
    if (jsonResponse?.token) {
      const logUserResponse = await AUTHApi.security.logUser.loginCheck.create(username, password, jsonResponse.token);
      const logUserJsonResponse = await logUserResponse.json();

      if (logUserResponse.status === 200) {
        if (logUserJsonResponse?.token) {
          setToken(logUserJsonResponse.token);
        }
        if (logUserJsonResponse?.refresh_token) {
          setRefreshToken(logUserJsonResponse.refresh_token);
        }
        // on check si l'entité à un service et s'il est actif
        const serviceActive = isUserHaveServiceActive();
        if (!serviceActive) {
          return false;
        }
        return logUserJsonResponse;
      }
      throw new Error(logUserJsonResponse.description);
    }
  }
  throw new Error(jsonResponse.description);
};

// export const authenticate2 = async (username: string, password: string, retry: boolean = true, forceLog?: boolean) => {
//   if (forceLog || !(await checkAuthenticate())) {
//     const token = await getToken();
//
//     let response;
//
//     if (token) {
//       response = await AUTHApi.security.logUser.loginCheck.create(username, password);
//     } else {
//       response = await AUTHApi.security.loginCheck.create(username, password);
//     }
//
//     const jsonResponse = await response.json();
//     if (response.status === 401 && retry) {
//       if (
//         jsonResponse.description === 'Expired JWT Token' ||
//         jsonResponse.description === 'Token is expired' ||
//         jsonResponse.description === 'Unauthorized'
//       ) {
//         await refreshAuthData(token, await getRefreshToken());
//         await authenticate2(username, password, false);
//       }
//     }
//     if (response.status === 200) {
//       if (jsonResponse?.token) {
//         setToken(jsonResponse.token);
//       }
//       if (jsonResponse?.refresh_token) {
//         setRefreshToken(jsonResponse.refresh_token);
//       }
//       return response;
//     }
//     throw new Error(jsonResponse.description);
//   }
// };

export const authenticateImpersonate = async (thieveUsername: string, impersonatedUsername: string) => {
  if (!(await getToken())) {
    throw new Error('You need to an user before use impersonation');
  }

  const response = await AUTHApi.security.logUser.loginImpersonate.create(thieveUsername, impersonatedUsername);

  // On set le token et le refreshToken
  if (response.token) {
    setImpersonatedToken(await getToken());
    setToken(response.token);
  }

  if (response.refresh_token) {
    setRefreshToken(response.refresh_token);
  }

  return response;
};
