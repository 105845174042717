export type ProductPropertyArray = { id: string; name: string; value: [string, string] };
export type ProductPropertyStringValue = { id: string; name: string; value: string };
export type ProductPropertyNumberValue = { id: string; name: string; value: number };
export type ProductPropertyBooleanValue = { id: string; name: string; value: boolean };

export type ProductProperties = {
  codeActivite?: ProductPropertyStringValue;
  codeConcept?: ProductPropertyStringValue;
  codeSalon?: ProductPropertyStringValue;
  codeUdiDi?: ProductPropertyStringValue;
  codeValEtatStock?: ProductPropertyNumberValue;
  collection?: ProductPropertyArray;
  colori?: ProductPropertyStringValue;
  color?: ProductPropertyArray;
  colorCode?: ProductPropertyStringValue;
  colorTemple?: ProductPropertyArray;
  colorFront?: ProductPropertyArray;
  communication?: ProductPropertyArray;
  concept?: ProductPropertyStringValue;
  customerDistribWorldDateIn?: ProductPropertyStringValue;
  customerDistribWorldDateOut?: ProductPropertyStringValue;
  customerDistribWorldFlag?: ProductPropertyArray;
  customerOpticianFranceDateIn?: ProductPropertyStringValue;
  customerOpticianFranceDateOut?: ProductPropertyStringValue;
  customerOpticianFranceFlag?: ProductPropertyArray;
  customerOpticianWorldDateIn?: ProductPropertyStringValue;
  customerOpticianWorldDateOut?: ProductPropertyStringValue;
  customerOpticianWorldFlag?: ProductPropertyArray;
  dateSav?: ProductPropertyStringValue;
  dbl?: ProductPropertyStringValue;
  flex?: ProductPropertyStringValue;
  fullAjustable?: ProductPropertyStringValue;
  gender?: ProductPropertyArray;
  gradientLenses?: ProductPropertyStringValue;
  hauteurPlv?: ProductPropertyNumberValue;
  largeurPlv?: ProductPropertyNumberValue;
  lengthTemple?: ProductPropertyStringValue;
  level?: ProductPropertyStringValue;
  longueurPlv?: ProductPropertyNumberValue;
  marmotteExport?: ProductPropertyArray;
  marmotteFrance?: ProductPropertyArray;
  marmotteInExport?: ProductPropertyStringValue;
  marmotteInFrance?: ProductPropertyStringValue;
  marmotteOutExport?: ProductPropertyStringValue;
  marmotteOutFrance?: ProductPropertyStringValue;
  marmotteOutP4?: ProductPropertyStringValue;
  marmotteOutP5?: ProductPropertyStringValue;
  marmotteP4?: ProductPropertyArray;
  marmotteP5?: ProductPropertyArray;
  material?: ProductPropertyArray;
  materialFront?: ProductPropertyArray;
  materialTemple?: ProductPropertyArray;
  polarizedLenses?: ProductPropertyStringValue;
  premium?: ProductPropertyStringValue;
  productId?: ProductPropertyStringValue;
  repDistribWorldDateIn?: ProductPropertyStringValue;
  repDistribWorldDateOut?: ProductPropertyStringValue;
  repDistribWorldFlag?: ProductPropertyArray;
  repExport?: ProductPropertyArray;
  repInExport?: ProductPropertyStringValue;
  repOpticianFranceDateOut?: ProductPropertyStringValue;
  repOpticianFranceFlag?: ProductPropertyArray;
  repOpticianUsaDateOut?: ProductPropertyStringValue;
  repOpticianUsaFlag?: ProductPropertyArray;
  repOpticianWorldDateOut?: ProductPropertyStringValue;
  repOpticianWorldFlag?: ProductPropertyArray;
  repOutExport?: ProductPropertyStringValue;
  rfa?: ProductPropertyStringValue;
  sav?: ProductPropertyStringValue;
  seuilSav?: ProductPropertyNumberValue;
  shape?: ProductPropertyArray;
  sizeA?: ProductPropertyNumberValue;
  sizeB?: ProductPropertyNumberValue;
  sizeEd?: ProductPropertyNumberValue;
  standardNonStandard?: ProductPropertyStringValue;
  status?: ProductPropertyStringValue;
  stockPromoSale?: ProductPropertyStringValue;
  textGift?: ProductPropertyStringValue;
  type?: ProductPropertyArray;
  typeFrame?: ProductPropertyArray;
  typeProduct?: ProductPropertyArray;
  widthLenses?: ProductPropertyStringValue;
};

export type ProductImage = {
  id: string;
  name: string;
  contentUrl: string;
  width: string;
  height: string;
};

export type ProductOffer = {
  availability: string;
  availabilityStarts: string;
  exchangeRateToEuro: number;
  id: string;
  inventoryLevel: number;
  price: number;
  priceCurrency: string;
  productId: string;
};

export type ProductImages = {
  [key: string]: ProductImage;
};

export type ProductMarketing = {
  id: string;
  productId: string;
  value: { [lngKey: string]: string };
};
export type ProductMarketings = { [key: string]: ProductMarketing };

export enum MarketingFieldStatus {
  NEW = 'NEW',
  CHANGED = 'CHANGED',
  UP_TO_DATE = 'up to date',
  DELETED = 'deleted',
  ERROR = 'error',
}
export type MarketingTranslation = { lng: string; value: string; status: MarketingFieldStatus };
export type MarketingField = {
  title: string;
  id: string;
  translations: Array<MarketingTranslation>;
  status: MarketingFieldStatus;
  selected: boolean;
};
export type MarketingFields = Array<MarketingField>;

const isMarketingTranslation = (translation: any): translation is MarketingTranslation => {
  return (
    (translation as MarketingTranslation).lng !== undefined &&
    (translation as MarketingTranslation).value !== undefined &&
    (translation as MarketingTranslation).status !== undefined
  );
};

export const isMarketingField = (field: any): field is MarketingField => {
  return (
    (field as MarketingField).title !== undefined &&
    (field as MarketingField).translations !== undefined &&
    (field as MarketingField).translations.every(translation => isMarketingTranslation(translation)) &&
    (field as MarketingField).status !== undefined &&
    (field as MarketingField).selected !== undefined
  );
};

export type Product = {
  '@id': string;
  id: string;
  sku: string;
  ean: string;
  brand: string;
  category: string;
  discount: { [key: string]: { currency: string; id: string; numericValue: string; percentValue: string } };
  images: { [key: string]: { contentUrl: string; height: string; id: string; name: string; width: string } };
  model: string;
  morelEan: string;
  name: string;
  offers: {
    [key: string]: ProductOffer;
  };
  properties: ProductProperties;
  support: null;
  marketing: ProductMarketings;
};

export const isProduct = (obj: any): obj is Product => {
  return (obj as Product).offers !== undefined;
};

export const isPlvProduct = (obj: any): obj is Product => {
  return (obj as Product).properties?.typeFrame?.value[0] === 'PLV';
};
