import { callQGAPI, DEFAULT_QUERY_OPTIONS } from '../../../callApi';
import { Product, QueryOptions } from '../../../types';
import { NetworkFunction } from '../../../types/Metadata';

export const getProducts: NetworkFunction<Product> = (
  params: string,
  queryOptions: QueryOptions = DEFAULT_QUERY_OPTIONS,
) => {
  return callQGAPI(
    '/catalog/products',
    params,
    {
      method: 'GET',
      headers: {
        Accept: queryOptions.accept,
      },
    },
    queryOptions.toJson,
  );
};
