import { ping } from './ping';

export const selectNearestApiHost = async (hosts: Array<string>) => {
  try {
    if (hosts.length > 1) {
      const pingResult: Array<{ host: string; latency: number }> = [];
      for (let i = 0; i < hosts.length; i++) {
        const host = hosts[i];
        if (host) {
          const latency: number = await ping(host);
          pingResult.push({ host: host, latency });
        }
      }
      if (pingResult.length === 0) {
        throw new Error('No valid Url to ping, You need to provide an auth api url in initGlobalAuth function');
      }

      pingResult.sort((a, b) => a.latency - b.latency);
      return pingResult[0].host;
    }

    if (hosts.length === 1 && hosts[0]) {
      return hosts[0];
    }
  } catch (e) {
    throw new Error(e.message);
  }

  throw new Error('You need to provide at least one url for authApi');
};
