import { callQGAPI, DEFAULT_QUERY_OPTIONS } from '../../../callApi';
import { QueryOptions } from '../../../types';

export const getImageForProduct = (
  productId: string,
  params: string,
  queryOptions: QueryOptions = DEFAULT_QUERY_OPTIONS,
) => {
  return callQGAPI(
    '/catalog/products/' + productId + '/images',
    params,
    {
      method: 'GET',
      headers: {
        Accept: queryOptions.accept,
      },
    },
    queryOptions.toJson,
  );
};
