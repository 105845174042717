import { callQGAPI, DEFAULT_QUERY_OPTIONS } from '../../../callApi';
import { QueryOptions } from '../../../types';

export const getCommercial = (
  commercialRessource: string,
  params: string,
  queryOptions: QueryOptions = DEFAULT_QUERY_OPTIONS,
) => {
  return callQGAPI(
    commercialRessource,
    params,
    {
      method: 'GET',
      headers: {
        Accept: queryOptions.accept,
      },
    },
    queryOptions.toJson,
  );
};
