import { callAuthAPI } from '../../../callApi';
import { LoginResponse } from '../../../types';

export const meService = (serviceCode: string) => {
  return callAuthAPI(`/me/services/${serviceCode}`, '', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });
};
